class Zone {
  id;
  constructor(data) {
    this.id = data.id || '';
    this.name = data.name || '';
    this.priceShared = data.priceShared || '';
    this.pricePrivate = data.pricePrivate || '';
    this.description = data.description || '';
    this.code = data.code || '';
    this.status = data.status || '';
  }
}
var DataConverter = {
  toFirestore: function(zone) {
    return {
      name: zone.name,
      priceShared: zone.priceShared,
      pricePrivate: zone.pricePrivate,
      description: zone.description,
      code: zone.code,
      status: zone.status,
    }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Zone(data)
  }
}
export {
  Zone,
  DataConverter
}