import BaseModel from './BaseModel';
import { DataConverter as ZoneDataConverter } from '@/FirestoreDataConverters/Zone.js';

export default class Zone extends BaseModel {
    constructor(options) {
        super(options);
    }

    getAll() { //get all data of zone
        return this.db.collection('zone').withConverter(ZoneDataConverter);
    }
    findById(id) { ///find zona by id 
        return this.db.collection('zone').doc(id).withConverter(ZoneDataConverter);
    }
    add(obj) { // insert record
        return this.db.collection('zone').withConverter(ZoneDataConverter).add(obj);
    }
    update(id, obj) { //update record of zone
        return this.db.collection('zone').withConverter(ZoneDataConverter).doc(id).update(obj);
    }
    delete(id) { // delete record of zone
        return this.db.collection('zone').doc(id).delete();
    }
}