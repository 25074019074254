<template>
  <div>
    <portal to="title-page">
      <h1>Editar Zona</h1>
    </portal>
    <v-card class="mx-auto elevation-1">
      <v-card-title>
        <v-icon large left>{{mdiPencil}}</v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-spacer></v-spacer>
        <v-btn small class="ml-2" @click="$router.go(-1)">
          <v-icon>{{mdiArrowLeft}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="zone.name"
                :rules="nameRules"
                :prepend-icon="mdiText"
                required
                dense
                label="Nombre"
                hint="Ingrese el nombre de la zona"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Precio privado"
                :prepend-icon="mdiCurrencyUsd"
                type="number"
                hint="Tarifa para transporte privado"
                color="primary"
                v-model="zone.pricePrivate"
                required
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Precio colectivo"
                :prepend-icon="mdiCurrencyUsd"
                type="number"
                color="primary"
                v-model="zone.priceShared"
                hint="Tarifa para transporte colectivo"
                required
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Descripcion</p>
              <vue-editor
                id="description"
                :class="zone.description != null && zone.description == '' ? 'error' : ''"
                v-model="zone.description"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm || zone.name == null || zone.description == '' "
                color="primary"
                class="mr-4"
                @click="validateForm"
              >Guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template> 
<script>
import { mdiArrowLeft, mdiFlag, mdiText, mdiClose, mdiPencil, mdiCurrencyUsd } from "@mdi/js";
import { VueEditor } from "vue2-editor";
import Zone from "@/models/Zone.js";
export default {
  name: "ZoneEdit",
  components: { VueEditor },
  data() {
    return {
      zoneModel: new Zone(),
      ///////////////////////////////////////////////////////
      mdiArrowLeft: mdiArrowLeft,
      mdiFlag: mdiFlag,
      mdiText: mdiText,
      mdiClose: mdiClose,
      mdiPencil: mdiPencil,
      mdiCurrencyUsd: mdiCurrencyUsd,
      ///////////////////////////////////////////////////////
      zone: [],
      zoneId: "",
      validForm: true,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      nameRules: [(v) => !!v || "El nombre es obligatorio"],
      ref: null,
      descriptionRules: [
        (v) => !!v || "La descripcion es obligatoria",
        (v) =>
          (v && v.length >= 10) ||
          "La descripcion debe ser de al menos 10 caracteres",
      ],
      snackbar: {
        show: false,
        color: "",
        message: "",
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        let docDataZona = {
          name: this.zone.name,
          description: this.zone.description,
          pricePrivate: this.zone.pricePrivate,
          priceShared: this.zone.priceShared,
        };
        this.zoneModel
          .update(this.zoneId, docDataZona)
          .then(() => {
            loading.hide();
            this.showSnackbarSuccess("La zona se agrego correctamente");
            this.$router.push({ path: "/backend/admin/zone" });
          })
          .catch((error) => {
            console.log(error);
            loading.hide();
            this.showSnackbarError(
              "Error al crear la zona, por favor contacte al Administrador si el problema persiste"
            );
          });
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "green";
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "red";
    },
  },
  mounted() {
    this.zoneId = this.$route.params.id;
    this.zoneModel.findById(this.zoneId).onSnapshot((doc) => {
      this.zone = doc.data();
    });
  },
};
</script>
<style>
#app .quillWrapper.error {
  background-color: transparent !important;
}
.quillWrapper.error > div {
  border-color: #ff5252;
  background-color: transparent;
}
</style>